import './FilterComponent.scss';

import { Drawer, Radio, Select, SelectProps, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

import FilterButton from './FilterButton';
import { OfficeService } from 'app/services/office';
import { StatusChipStyleMap } from './util/ChipStyleMaps';
import { Typography } from '@mui/material';
import useDebounce from 'app/hooks/UseDebounce';

interface SelectOption {
  value: string;
  label: string;
}

const statuses: SelectOption[] = [
  { value: 'NOT_STARTED,PROCESSING', label: 'Max Reviewing' },
  {
    value: 'LOOP_NOT_FOUND,DOCS_MISSING,VALIDATION_FAILED',
    label: 'Needs Action',
  },
  { value: 'COMPLETED', label: 'Max Approved' },
];

const checkTypes: SelectOption[] = [
  {
    value: 'IL_BW_CC',
    label: 'Commission Check',
  },
  {
    value: 'IL_BW_EMR',
    label: 'Earnest Money Receipt',
  },
  {
    value: 'IL_BW_EMP',
    label: 'Earnestly',
  },
];

type TagRender = SelectProps['tagRender'];

const FilterComponent: React.FC<{
  isCompliance: boolean | undefined;
  onFilterChange: (
    statuses: string[],
    checkTypes: string[],
    transactionOffices: string[],
    readyToScan: boolean | null,
    labels: string[]
  ) => void;
}> = ({ isCompliance, onFilterChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [statusLabels, setStatusLabels] = useState<string[]>([]);
  const [selectedCheckTypes, setSelectedCheckTypes] = useState<string[]>([]);
  const [checkTypeLabels, setCheckTypeLabels] = useState<string[]>([]);

  const [readyToScan, setReadyToScan] = useState<boolean | null>(null);
  const [readyToScanLabel, setReadyToScanLabel] = useState<string>('');
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  const [filteredTransactionOffices, setFilteredTransactionOffices] = useState<
    Record<string, any>[]
  >([]);
  const [transactionOfficeQuery, setTransactionOfficeQuery] =
    useState<string>('');
  const [selectedTransactionOffices, setSelectedTransactionOffices] = useState<
    Record<string, string>[]
  >([]);

  const debouncedTransactionOfficeQuery = useDebounce(
    transactionOfficeQuery,
    100
  );

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const clearFilters = () => {
    setSelectedStatuses([]);
    setSelectedCheckTypes([]);
    setSelectedTransactionOffices([]);
    setStatusLabels([]);
    setCheckTypeLabels([]);
    setReadyToScan(null);
    setReadyToScanLabel('');
    setActiveFiltersCount(0);
  };

  const handleStatusSelect = (value: string[]) => {
    console.log('Status values: ', value);

    // Maintain a list of the labels as well for UI rendering
    if (value.length === 0 || value.includes('')) {
      setStatusLabels([]);
    } else {
      let newStatusLabels: string[] = [];
      value.map((val) => {
        let label = statuses.find((o) => o.value === val)?.label;
        if (label) {
          newStatusLabels.push(label);
        }
      });
      setStatusLabels(newStatusLabels);
    }

    setSelectedStatuses(value);
  };

  // ---------- Check type select handler methods ----------
  const handleCheckTypeSelect = (value: string[]) => {
    console.log('Check type values: ', value);

    // Maintain a list of the labels as well for UI rendering
    if (value.length === 0 || value.includes('')) {
      setCheckTypeLabels([]);
    } else {
      let newCheckTypeLabels: string[] = [];
      value.map((val) => {
        let label = checkTypes.find((o) => o.value === val)?.label;
        if (label) {
          newCheckTypeLabels.push(label);
        }
      });
      setCheckTypeLabels(newCheckTypeLabels);
    }

    setSelectedCheckTypes(value);
  };

  // ---------- Transaction office select handler methods ----------

  const handleTransactionOfficeOnSelect = (option: Record<string, any>) => {
    console.log('Selected Transaction Office: ', option);
    setSelectedTransactionOffices([...selectedTransactionOffices, option]);
  };

  const handleTransactionOfficeOnDeselect = (option: Record<string, any>) => {
    console.log('Deselected Transaction Office: ', option);
    setSelectedTransactionOffices(
      selectedTransactionOffices.filter(
        (office) => office.value !== option.value
      )
    );
  };

  // ---------------------------------------------------------------
  // ---------- Ready to scan radio button handler methods ---------

  // Handle ready to scan select
  // TODO: Consider moving this to a map when implementing close feature from the chips itself
  const handleReadyToScanRadioButton = (value: boolean | null) => {
    console.log('value', value);
    let readyToScanLabel = '';

    switch (value) {
      case true:
        readyToScanLabel = 'Ready to Scan';
        break;
      case false:
        readyToScanLabel = 'Not Ready to Scan';
        break;
      default:
        readyToScanLabel = '';
        break;
    }

    setReadyToScanLabel(readyToScanLabel);
    setReadyToScan(value);
  };

  // --------------------------------------------
  // ---------- UseEffect hooks -----------------

  /**
   * Fetch the transaction offices based on the debounced query
   */
  useEffect(() => {
    // Depending on the mode, the partner ID will be different
    // While this might not be a real use case yet, it is good to have this in place
    const mode = isCompliance ? 'Compliance' : 'OfficeAdmin';

    OfficeService.getOffices(mode, debouncedTransactionOfficeQuery).then(
      (response) => {
        setFilteredTransactionOffices(response.data?.data || []);
      }
    );
  }, [debouncedTransactionOfficeQuery]);

  /**
   * Trigger the onFilterChange callback when the selected statuses, transaction offices or ready to scan value changes
   */
  useEffect(() => {
    const updatedLabels = computeUpdatedLabels();
    setActiveFiltersCount(updatedLabels.length);
    let selectedTransactionOfficesIDs = selectedTransactionOffices.map(
      (office) => office.value
    );
    onFilterChange(
      selectedStatuses,
      selectedCheckTypes,
      selectedTransactionOfficesIDs,
      readyToScan,
      updatedLabels
    );
  }, [
    selectedStatuses,
    selectedTransactionOffices,
    readyToScan,
    selectedCheckTypes,
  ]);

  // --------------------------------------------------------
  // ---------- Select Tag render methods -------------------

  /**
   * Custom Tag render for the check type select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the status select
   */
  const selectedCheckTypeTagRender: TagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const label = checkTypes.filter((o) => o.value === value)[0].label;

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.25rem',
          fontSize: '0.9rem',
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  /**
   * Custom Tag render for the status select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the status select
   */
  const selectStatusTagRender: TagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const label = statuses.filter((o) => o.value === value)[0].label;

    // const { backgroundColor, color, border } = StatusChipStyleMap[label];

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.25rem',
          fontSize: '0.9rem',
          // backgroundColor,
          // color,
          // border,
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  /**
   * Custom Tag render for the transaction office select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the transaction office select
   */
  const selectTransactionOfficeTagRender: TagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.3rem',
          fontSize: '0.9rem',
          // backgroundColor: '#2F78EB',
          // color: 'white',
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  // ------------------------------------------------
  // ----------- Utility methods --------------------

  /**
   *  Compute the updated labels based on the selected statuses, transaction offices and ready to scan value
   *  @returns Updated labels
   */
  const computeUpdatedLabels = () => {
    let updatedLabels: string[] = [];

    if (selectedStatuses.length > 0) {
      updatedLabels = [...statusLabels];
    }
    if (selectedCheckTypes.length > 0) {
      updatedLabels = [...updatedLabels, ...checkTypeLabels];
    }
    if (selectedTransactionOffices.length > 0) {
      let transactionOfficeLabels = selectedTransactionOffices.map(
        (office) => office.label
      );
      updatedLabels = [...updatedLabels, ...transactionOfficeLabels];
    }
    if (readyToScan !== null) {
      updatedLabels = [...updatedLabels, readyToScanLabel];
    }

    return updatedLabels;
  };

  // -------------------------------------------------
  // ---------- Filter Component JSX -----------------

  return (
    <div className="filter-component">
      <FilterButton
        onClick={toggleDropdown}
        clearFilters={clearFilters}
        selectedCount={activeFiltersCount}
      />
      <Drawer
        title={<Typography fontSize="1.5rem">Filters</Typography>}
        open={showDropdown}
        onClose={toggleDropdown}
        width={350}
      >
        {/* Status filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Status
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Status"
            tagRender={selectStatusTagRender}
            value={selectedStatuses}
            onChange={(value) => handleStatusSelect(value)}
            options={statuses}
            optionRender={(option) => <div>{option.label}</div>}
            allowClear
          />
        </div>

        {/* Check type filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Check Type
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Check Type"
            tagRender={selectedCheckTypeTagRender}
            value={selectedCheckTypes}
            onChange={(value) => handleCheckTypeSelect(value)}
            options={checkTypes}
            optionRender={(option) => <div>{option.label}</div>}
            allowClear
          />
        </div>

        {/* Ready to scan filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Ready to Scan
          </Typography>
          <Radio.Group
            className="ready-to-scan-radio"
            onChange={(event) =>
              handleReadyToScanRadioButton(event.target.value)
            }
            defaultValue={null}
            value={readyToScan}
          >
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
            <Radio.Button value={null}>All</Radio.Button>
          </Radio.Group>
        </div>

        {/* Transaction office filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Transaction Office
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Transaction Office"
            tagRender={selectTransactionOfficeTagRender}
            value={selectedTransactionOffices}
            onSearch={(value) => {
              console.log('Value: ', value);
              setTransactionOfficeQuery(value);
            }}
            filterOption={false}
            onSelect={(value, option) => {
              handleTransactionOfficeOnSelect(option);
            }}
            onDeselect={(value, option) =>
              handleTransactionOfficeOnDeselect(option)
            }
            onClear={() => setSelectedTransactionOffices([])}
            onBlur={() => setTransactionOfficeQuery('')}
            options={filteredTransactionOffices.map((office) => ({
              value: office._id,
              label: office.name,
            }))}
            allowClear
          />
        </div>
      </Drawer>
    </div>
  );
};

export default FilterComponent;
