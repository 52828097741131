const documentNameMap: Record<string, string> = {
  // IL_MAIN: 'Multi-Board Residential Real Estate Contract',
  IL_MAIN: 'Purchase Contract',
  IL_BW_BAA: 'Exclusive Buyer/Tenant Representation Agreement',
  IL_BW_SDF: 'Sales Data Form',
  IL_BW_RTSMA: 'Exclusive Right To Sell Marketing Agreement',
  IL_BW_MOD: 'Dual Multiple Offers Disclosure',
  CA_MAIN: 'California Main Document', // TODO: Get the actual name
  IL_BW_EM_CHECK: 'Earnest Money Receipt',
  IL_BW_COMM_CHECK: 'Commission Check',
  IL_BW_AFFL: 'Disclosure Statement of Affiliated Business Arrangement',
  IL_BW_RRPDR: 'Residential Real Property Disclosure Report',
  IL_BW_PNA: 'Private Network Addendum',
  IL_BW_LEA: 'MRED MLS Listing Exemption Authorization',
  IL_BW_DIRH:
    'Disclosure of Information on Radon Hazards For Residential Real Property Sales or Purchases',
  IL_BW_DIAL:
    'Disclosure of Information and Acknowledgement Lead-Based Paint and or Lead-Based Paint Hazards',
  IL_BW_AEMA: 'Addendum to Exclusive Marketing Agreement',
  IL_BW_CC: 'Commission Check',
  IL_BW_DSABA: 'Disclosure Statement of Affiliated Business Arrangement',
  IL_BW_EMR: 'Earnest Money',
  IL_BW_HUD: 'HUD Statement',
  IL_BW_CS_B: 'Commission Statement',
  IL_BW_CS_T: 'Commission Statement',
  IL_BW_EMP : 'Earnestly Deposit',

  FL_MAIN: 'Florida Residential Contract',
};

export { documentNameMap };
