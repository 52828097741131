export const CheckStatusMap: Record<string, string> = {
  NOT_STARTED: 'Max Reviewing',
  PROCESSING: 'Max Reviewing',
  LOOP_NOT_FOUND: 'Needs Action : Loop Not Found',
  DOCS_MISSING: 'Needs Action : Documents Missing',
  VALIDATION_FAILED: 'Needs Action : Validation Failed',
  COMPLETED: 'Max Approved',
};


export const CheckStatusMapOriginal: Record<string, string> = {
  NOT_STARTED: 'Not Started',
  PROCESSING: 'Processing',
  LOOP_NOT_FOUND: 'Loop Not Found',
  DOCS_MISSING: 'Documents Missing',
  VALIDATION_FAILED: 'Validation Failed',
  COMPLETED: 'Max Approved',
};
