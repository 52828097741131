import { AppURLs } from 'app/utils/appURLs';
import api from 'app/utils/api';
import { removeAuthToken } from 'app/utils/localStorageHandler/auth';

export class UserService {
  public async validateInvite(inviteCode: string) {
    return api.get(`/user/validate-invite/${inviteCode}`);
  }

  public async createAgentAccount(
    name: string,
    phone: string,
    password: string,
    inviteCode: string
  ) {
    return api.post(`/user/signup`, {
      name,
      phone,
      password,
      inviteCode,
      role: 'Agent',
    });
  }

  public async userLogin(email: string, password: string) {
    return api.post(`/user/login`, {
      email,
      password,
    });
  }

  public userLogout() {
    // TODO: In case API call is required, add it
    removeAuthToken();
    // window.location.href = '/v2/agent/login';
    window.location.href = AppURLs.agentLogin();
  }

  public async getUserProfile() {
    return api.get(`/user/profile`);
  }
}
