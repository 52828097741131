import './UploadDocumentComponent.scss';

import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';
import { Button, Modal, Upload, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { ComplianceService } from 'app/services/compliance';
import { InboxOutlined } from '@ant-design/icons';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import cross from 'assets/icons/highlight_off.svg';
import tick from 'assets/icons/tick.svg';
import { toast } from 'react-toastify';
import useDebounce from 'app/hooks/UseDebounce';

const { Dragger } = Upload;

const UploadDocumentComponent: React.FC<{ onSuccess: () => void }> = ({
  onSuccess,
}) => {
  const [modalState, setModalState] = useState({
    isUploadModalVisible: false,
    isCheckModalVisible: false,
  });

  const [fileData, setFileData] = useState({
    fileToUpload: null as any,
    previewUrl: null as string | null,
  });
  const [isNewTransaction, setIsNewTransaction] = useState(false);
  const [showAgentsList, setShowAgentsList] = useState(true);

  const [formData, setFormData] = useState({
    propertyAddress: { value: '', label: '' },
    selectedAgentName: { value: '', label: '' },
    selectedDocumentType: { value: '', label: '' },
  });

  const [loadingProperties, setLoadingProperties] = useState(false);
  const [propertyOptions, setPropertyOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [loadingAgents, setLoadingAgents] = useState(false);
  const [agentOptions, setAgentOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const documentTypeOptions = isNewTransaction
    ? [
        { value: 'IL_BW_EMR', label: 'Earnest Money Check' },
        // { value: 'IL_BW_CC', label: 'Commission Check' },
        // { value: 'IL_BW_UC', label: 'Other' },
      ]
    : [
        { value: 'IL_BW_EMR', label: 'Earnest Money Check' },
        // { value: 'IL_BW_CC', label: 'Commission Check' },
        // { value: 'IL_BW_UC', label: 'Other Check' },
        { value: 'UNKNOWN_DOC', label: 'Other Document' },
      ];

  const debouncedSearchTerm = useDebounce(
    formData?.selectedAgentName?.label,
    500
  );

  const debouncedPropertySearchTerm =
    useDebounce(formData?.propertyAddress?.label, 500) || '';

  const fetchAgents = useCallback(async () => {
    if (!debouncedSearchTerm) return;

    setLoadingAgents(true);
    try {
      const response = await ComplianceService.getAgent(debouncedSearchTerm);
      const agents = response.data.data.map((agent: any) => ({
        label: agent.name,
        value: agent._id,
      }));
      setAgentOptions(agents);
    } catch (error) {
      message.error('Failed to load agents');
    } finally {
      setLoadingAgents(false);
    }
  }, [debouncedSearchTerm]);

  const fetchProperties = useCallback(async () => {
    if (!debouncedPropertySearchTerm) return;

    setLoadingProperties(true);
    try {
      const response = await ComplianceService.getPropertyAddress(
        debouncedPropertySearchTerm
      );
      const properties = response.data.data.map((property: any) => ({
        label: property.name,
        value: property._id,
      }));
      setPropertyOptions(properties);
    } catch (error) {
      message.error('Failed to load properties');
    } finally {
      setLoadingProperties(false);
    }
  }, [debouncedPropertySearchTerm]);

  useEffect(() => {
    fetchProperties();
  }, [debouncedPropertySearchTerm]);

  useEffect(() => {
    fetchAgents();
  }, [debouncedSearchTerm]);

  const showModal = () => {
    setModalState({ ...modalState, isUploadModalVisible: true });
  };

  const handleOk = () => {
    if (fileData.fileToUpload) {
      uploadFile();
      setModalState({
        ...modalState,
        isUploadModalVisible: false,
        isCheckModalVisible: true,
      });
    } else {
      message.error('Please select a file to upload.');
    }
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isUploadModalVisible: false });
    setFileData({ fileToUpload: null, previewUrl: null });
  };

  const uploadFile = () => {
    if (fileData.fileToUpload) {
      const objectUrl = URL.createObjectURL(
        fileData.fileToUpload as unknown as Blob
      );
      setFileData({ ...fileData, previewUrl: objectUrl });
    } else {
      message.error('Failed to load the file for preview.');
    }
  };

  useEffect(() => {
    return () => {
      if (fileData.previewUrl) {
        URL.revokeObjectURL(fileData.previewUrl);
      }
    };
  }, [fileData.previewUrl]);

  useEffect(() => {
    if (!modalState.isCheckModalVisible && !modalState.isUploadModalVisible) {
      setFormData({
        propertyAddress: { value: '', label: '' },
        selectedAgentName: { value: '', label: '' },
        selectedDocumentType: { value: '', label: '' },
      });
      setIsNewTransaction(false);
    }
  }, [modalState.isCheckModalVisible, modalState.isUploadModalVisible]);

  const handleCheckSubmit = async () => {
    if (
      formData.propertyAddress &&
      formData.selectedDocumentType.value &&
      fileData.fileToUpload
    ) {
      if (isNewTransaction && !formData.selectedAgentName.value) {
        message.error('Please select an agent');
        return;
      }
      setIsButtonLoading(true);

      const { propertyAddress, selectedAgentName, selectedDocumentType } =
        formData;
      const file = fileData.fileToUpload;

      try {
        if (isNewTransaction) {
          await ComplianceService.postCheck(
            selectedAgentName.value,
            selectedDocumentType.value,
            file,
            isNewTransaction,
            propertyAddress.value
          );
        } else {
          await ComplianceService.postCheck(
            selectedAgentName.value,
            selectedDocumentType.value,
            file,
            isNewTransaction,
            '',
            propertyAddress.value
          );
        }

        message.success('Check details saved successfully!');
        onSuccess();
        setModalState({ ...modalState, isCheckModalVisible: false });
        setFileData({ fileToUpload: null, previewUrl: null });
        setFormData({
          propertyAddress: { value: '', label: '' },
          selectedAgentName: { value: '', label: '' },
          selectedDocumentType: { value: '', label: '' },
        });
      } catch (error: any) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            autoClose: false,
          });
        } else {
          toast.error('Failed to save check details', {
            autoClose: false,
          });
        }
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      message.error('Please fill all the fields and upload a file.');
    }
  };

  const handleSelect = (field: keyof typeof formData, option: any) => {
    setFormData({ ...formData, [field]: option });
  };

  const handleCreateNewTransaction = () => {
    setIsNewTransaction(true);
    handleSelect('propertyAddress', {
      value: debouncedPropertySearchTerm,
      label: debouncedPropertySearchTerm,
    });
  };

  return (
    <>
      <Button
        style={{ height: '40px', borderRadius: '12px' }}
        type="primary"
        onClick={showModal}
      >
        Upload Check
      </Button>
      <Modal
        title="Upload Check"
        open={modalState.isUploadModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Upload"
      >
        <Dragger
          style={{
            width: '100%',
            marginBottom: '1rem',
          }}
          accept=".pdf"
          beforeUpload={(file) => {
            setFileData({ ...fileData, fileToUpload: file });
            return false;
          }}
          onRemove={() => {
            setFileData({ fileToUpload: null, previewUrl: null });
          }}
          fileList={fileData.fileToUpload ? [fileData.fileToUpload] : []}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Drag and drop your file here or click to upload
          </p>
          <p className="ant-upload-hint">Only supports .pdf files</p>
        </Dragger>
      </Modal>

      <Modal
        title="Upload Check Details"
        open={modalState.isCheckModalVisible}
        onOk={handleCheckSubmit}
        onCancel={() => {
          setFileData({ fileToUpload: null, previewUrl: null });
          setFormData({
            propertyAddress: { value: '', label: '' },
            selectedAgentName: { value: '', label: '' },
            selectedDocumentType: { value: '', label: '' },
          });
          setModalState({ ...modalState, isCheckModalVisible: false });
        }}
        okText={isButtonLoading ? 'Submitting...' : 'Submit'}
        cancelText="Cancel"
        okButtonProps={{ loading: isButtonLoading }}
        width={600}
      >
        <div className="file-preview">
          <img src={tick} alt="Tick" className="icon" />
          {fileData.previewUrl && <PictureAsPdfIcon />}
          <div className="file-info">
            <div className="file-name truncate">
              {fileData.fileToUpload?.name}
            </div>
            <div className="file-size">
              {(fileData.fileToUpload?.size / 1024).toFixed(0)} KB
            </div>
          </div>
          <img
            src={cross}
            alt="Close"
            className="icon cross-icon"
            onClick={() => {
              setFileData({ fileToUpload: null, previewUrl: null });
              setModalState({
                isUploadModalVisible: true,
                isCheckModalVisible: false,
              });
            }}
          />
        </div>
        <div className="dropdown-group">
          <div className="dropdown-row">
            {formData.propertyAddress?.value ? (
              <Chip
                label={formData.propertyAddress.label}
                onDelete={() => {
                  handleSelect('propertyAddress', null);
                  setPropertyOptions([]);
                  setIsNewTransaction(false);
                  setShowAgentsList(true);
                }}
                style={{
                  height: '3.5rem',
                  width: !showAgentsList ? '50%' : '',
                }}
              />
            ) : (
              <Autocomplete
                options={[
                  ...propertyOptions,
                  ...(debouncedPropertySearchTerm &&
                  propertyOptions.length === 0
                    ? [
                        {
                          label: `Create new transaction : "${debouncedPropertySearchTerm}"`,
                          value: debouncedPropertySearchTerm,
                        },
                      ]
                    : []),
                ]}
                getOptionLabel={(option) => option.label}
                loading={loadingProperties}
                onInputChange={(event, value) =>
                  handleSelect('propertyAddress', { label: value, value: '' })
                }
                onChange={(event, value) => {
                  if (value?.label?.startsWith('Create new transaction')) {
                    handleCreateNewTransaction();
                  } else {
                    handleSelect('propertyAddress', value);
                    setShowAgentsList(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Property Address"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProperties ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            {showAgentsList ? (
              <Autocomplete
                options={agentOptions}
                getOptionLabel={(option) => option.label}
                loading={loadingAgents}
                onInputChange={(event, value) =>
                  handleSelect('selectedAgentName', { label: value, value: '' })
                }
                onChange={(event, value) =>
                  handleSelect('selectedAgentName', value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select agent's name"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingAgents ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ) : null}
          </div>
          <Autocomplete
            options={documentTypeOptions}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) =>
              handleSelect('selectedDocumentType', value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Document Type"
                variant="outlined"
              />
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default UploadDocumentComponent;
