import './ComplianceCheck.scss';

import { Box, Chip, TextField } from '@mui/material';
import { Button, Empty, Modal, Popconfirm, message } from 'antd';
import { CheckStatusMap, CheckStatusMapOriginal } from './types';
import { Header, Row, Table } from 'app/components/common/Table';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CheckBuilder } from 'app/services/checkBuilder';
import { ComplianceService } from 'app/services/compliance';
import { Delete } from '@mui/icons-material';
import { DownloadCheckReport } from './util/DownloadCheckReport';
import FilterComponent from './FilterComponent';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import Paginator from 'app/components/common/Paginator';
import { StatusChipStyleMap } from './util/ChipStyleMaps';
import UploadDocumentComponent from './UploadDocumentComponent';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import dayjs from 'dayjs';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getShortDateString } from 'app/utils/datetime';
import useDebounce from 'app/hooks/UseDebounce';

interface ComplianceChecksPageProps {
  isCompliance?: boolean;
}

const ComplianceChecksPage = ({ isCompliance }: ComplianceChecksPageProps) => {
  const [progressBarLoading, setProgressBarLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [checks, setChecks] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedCheckType, setSelectedCheckType] = useState<string[]>([]);
  const [selectedTransactionOfficeID, setSelectedTransactionOfficeID] =
    useState<string[]>([]);
  const [readyToScan, setReadyToScan] = useState<boolean | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [activeStatusDropdown, setActiveStatusDropdown] = useState<
    number | null
  >(null);
  const statusDropdownRef = useRef<HTMLDivElement>(null);

  // To keep track of the active filters and render the chips
  const [filterLabels, setFilterLabels] = useState<string[]>([]);

  const toggleStatusDropdown = (index: number) => {
    if (!isAdmin) return;
    setActiveStatusDropdown((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      statusDropdownRef.current &&
      !statusDropdownRef.current.contains(event.target as Node)
    ) {
      setActiveStatusDropdown(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleChangeStatus = async (check: any, status: string) => {
    try {
      await ComplianceService.patchCheck(
        check?._id,
        check?.readyToDeposit,
        check?.notes,
        status
      );

      setChecks((prevChecks) =>
        prevChecks.map((c) =>
          c._id === check._id
            ? { ...c, processingStatus: { ...c.processingStatus, status } }
            : c
        )
      );

      setActiveStatusDropdown(null);
      message.success(
        `Check status updated to  ${CheckStatusMapOriginal[status]}!`
      );
    } catch (error: any) {
      message.error('Error updating status:', error);
    }
  };

  const isAdmin = localStorage.getItem('Admin');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const generateDropdownData = (check: any) => {
    return [
      [
        { label: 'Branch Office', value: check?.transactionOffice?.name },
        { label: 'Receiving Office', value: check?.receivingOffice?.name },
        ...(isCompliance &&
        (check.processingStatus.status === 'VALIDATION_FAILED' ||
          check.processingStatus.status === 'COMPLETED' ||
          check.processingStatus.status === 'LOOP_NOT_FOUND' ||
          check.processingStatus.status === 'DOCS_MISSING')
          ? [
              {
                label: 'View Validation',
                value: `/compliance/dashboard/validation/checkId=${check?._id}`,
                isButton: true,
              },
            ]
          : []),
      ],
      [
        { label: 'Agent Name', value: check?.agentName },
        // {
        //   label: 'Maxhome Link',
        //   text: check?.transactionId ? 'https://www.maxhome.ai' :  'Generating Maxhome Link...',
        //   value: check?.transactionId
        //     ? `/compliance/dashboard/transactions/${check?.transactionId}`
        //     : 'N/A',
        //   isLink: true,
        // },
        {
          label: 'Dotloop Link',
          text: check?.externalSourceID ? 'https://www.dotloop.com' : 'N/A',
          value: check?.externalSourceID
            ? `https://www.dotloop.com/m/loop?viewId=${check?.externalSourceID}`
            : 'N/A',
          isLink: check?.externalSourceID ? true : false,
        },
        {
          label: 'View Document',
          value: handleViewPdf,
          check: check,
          isModal: true,
        },
      ],
    ];
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleRowSelection = (e: any, rowId: number) => {
    setSelectedRowIds((prevSelectedRowIds) =>
      prevSelectedRowIds.includes(rowId)
        ? prevSelectedRowIds.filter((id) => id !== rowId)
        : [...prevSelectedRowIds, rowId]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectAll(checked);

    if (checked) {
      setSelectedRowIds(checks.map((_, i) => i));
    } else {
      setSelectedRowIds([]);
    }
  };

  const rowHeaders = isCompliance
    ? [
        'Select All',
        'Property Address',
        'Type of Check',
        'Submission Date',
        'Ready to Submit',
        'Status',
        'Remarks',
        'Delete',
      ]
    : [
        'Select All',
        'Property Address',
        'Type of Check',
        'Submission Date',
        'Ready to Submit',
        'Remarks',
        'Delete',
      ];

  const buttonFilters = [
    {
      label: 'Today',
      gte: dayjs().startOf('day').format('YYYY-MM-DD'),
    },
    {
      label: 'Yesterday',
      gte: dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
    },
    {
      label: 'This Week',
      gte: dayjs().startOf('week').format('YYYY-MM-DD'),
      lte: dayjs().endOf('week').format('YYYY-MM-DD'),
    },
    {
      label: 'This Month',
      gte: dayjs().startOf('month').format('YYYY-MM-DD'),
      lte: dayjs().endOf('month').format('YYYY-MM-DD'),
    },
  ];

  const handlePagination = (newPage: number) => {
    setPage(newPage);
  };

  const handleDownloadReport = () => {
    const selectedChecks = checks.filter((_, index) =>
      selectedRowIds.includes(index)
    );
    DownloadCheckReport(selectedChecks);
    setSelectedRowIds([]);
    setSelectAll(false);
  };

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return 'No Document found';
    }
  };

  const handleViewPdf = async (check: any) => {
    const doc = await fetchTransactionDocument(
      check.document,
      check.transactionId
    );
    setModalPdfUrl(doc);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    if (modalPdfUrl) URL.revokeObjectURL(modalPdfUrl);
  };

  const refreshTableData = useCallback(
    (source?: string) => {
      setProgressBarLoading(true);

      const isUploadSource = source === 'upload';
      const currentPage = isUploadSource ? 1 : page;
      const currentStatus = isUploadSource ? '' : selectedStatus.join(',');
      const currentCheckType = isUploadSource
        ? ''
        : selectedCheckType.join(',');
      const currentTransactionOfficeID = isUploadSource
        ? ''
        : selectedTransactionOfficeID.join(',');
      const currentFilter = isUploadSource
        ? { gte: '', lte: '' }
        : buttonFilters[activeIndex!] || {};
      const currentSearchTerm = isUploadSource ? '' : debouncedSearchTerm;
      const currentReadyToScan = isUploadSource ? null : readyToScan;

      if (isUploadSource) {
        setPage(1);
        setSelectedStatus([]);
        setActiveIndex(null);
        setSearchTerm('');
        setReadyToScan(null);
        setSelectedRowIds([]);
      }

      const checkBuilder = new CheckBuilder.builder()
        .setPage(currentPage)
        .setLimit(10)
        .setStatus(currentStatus)
        .setCheckType(currentCheckType)
        .setTransactionOfficeID(currentTransactionOfficeID)
        .setCreatedAtGte(currentFilter.gte || '')
        .setCreatedAtLte(currentFilter.lte || '')
        .setSearch(currentSearchTerm)
        .setReadyToDeposit(currentReadyToScan)
        .build();

      checkBuilder.fetchNext().then((response: any) => {
        setChecks(response.data);
        setTotalElements(response.total);
        setHasMore(checkBuilder.hasMore);
        setProgressBarLoading(false);
        setLoading(false);
        setSelectedRowIds([]);
      });
    },
    [
      page,
      selectedStatus,
      selectedCheckType,
      selectedTransactionOfficeID,
      readyToScan,
      activeIndex,
      debouncedSearchTerm,
      filterLabels,
    ]
  );

  const handleDeleteCheck = async (id: string) => {
    try {
      const response = await ComplianceService.deleteCheck(id);
      if (response.status === 200) {
        message.success('Check deleted successfully!');
        refreshTableData();
      }
    } catch (error: any) {
      message.error(error);
      console.error('Error deleting check:', error);
    }
  };

  useEffect(() => {
    refreshTableData();
  }, [refreshTableData]);

  const component = (
    <>
      <Box width="100%" boxSizing="border-box">
        {progressBarLoading && (
          <Box width="100%">
            <LoadingProgressBar />
          </Box>
        )}

        <div className="checks-page">
          <div className="header">Overview</div>

          <div className="content-container">
            <div className="section-header">
              Checks uploaded
              {isCompliance ? (
                <Button
                  disabled={selectedRowIds.length === 0}
                  style={{ height: '40px', borderRadius: '12px' }}
                  type="primary"
                  onClick={handleDownloadReport}
                >
                  Download Report
                </Button>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    disabled={selectedRowIds.length === 0}
                    style={{ height: '40px', borderRadius: '12px' }}
                    type="primary"
                    onClick={handleDownloadReport}
                  >
                    Download Report
                  </Button>
                  <UploadDocumentComponent
                    onSuccess={() => {
                      refreshTableData('upload');
                    }}
                  />
                </div>
              )}
            </div>

            <div className="filter-bar">
              <div className="button-filters">
                {buttonFilters.map((filter, i) => (
                  <div
                    className={`filter-button ${i === activeIndex ? 'active' : ''}`}
                    key={i}
                    onClick={() => {
                      setActiveIndex(i);
                      setPage(1);
                    }}
                  >
                    {filter.label}
                  </div>
                ))}
              </div>

              <div className="search-filters">
                <TextField
                  label="Search for property"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearchChange(e);
                  }}
                  InputProps={{
                    style: {
                      height: '44px',
                      backgroundColor: 'white',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      top: '-5px',
                    },
                  }}
                />

                <FilterComponent
                  isCompliance={isCompliance}
                  onFilterChange={(
                    statuses: string[],
                    checkTypes: string[],
                    transactionOfficeIDs: string[],
                    readyToScan: boolean | null,
                    labels: string[]
                  ) => {
                    setSelectedStatus(statuses);
                    setSelectedCheckType(checkTypes);
                    setSelectedTransactionOfficeID(transactionOfficeIDs);
                    setReadyToScan(readyToScan);
                    setFilterLabels(labels);
                    setPage(1);
                  }}
                />
              </div>
            </div>

            {filterLabels.length > 0 && (
              <div
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  padding: '1rem 1rem 0 1rem',
                  alignItems: 'left',
                  justifyContent: 'left',
                  display: 'flex',
                }}
              >
                {filterLabels.map((label, i) => (
                  <Chip
                    key={i}
                    label={label}
                    style={{
                      margin: '0.5rem',
                      // ...(StatusChipStyleMap[label] || {
                      //   backgroundColor: '#2F78EB',
                      //   color: 'white',
                      // }),
                    }}
                    // onDelete={() => {
                    // // TODO: Implement this functionality
                    // }}
                  />
                ))}
              </div>
            )}

            <div className="table-section">
              <Table borderRadius="12px">
                <Header background="#d8d8d870">
                  {rowHeaders.map((header, i) => (
                    <span key={i}>
                      {header === 'Select All' ? (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.25rem',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            style={{ height: '14px', width: '14px' }}
                          />
                          Select All
                        </span>
                      ) : (
                        header
                      )}
                    </span>
                  ))}
                </Header>
                {checks.length ? (
                  checks.map((check, i) => (
                    <Row
                      key={`${i}`}
                      isCompliance={isCompliance}
                      dropdownData={generateDropdownData(check)}
                    >
                      {
                        <span>
                          <input
                            type="checkbox"
                            style={{ height: '14px', width: '14px' }}
                            checked={selectedRowIds.includes(i)}
                            onChange={(e) => handleRowSelection(e, i)}
                          />
                        </span>
                      }
                      <span>{check?.transactionName || '-'}</span>
                      <span>{documentNameMap[check?.type]}</span>
                      <span>{getShortDateString(check?.createdAt)}</span>
                      <span>{check?.readyToDeposit ? 'Yes' : 'No'}</span>
                      {isCompliance ? (
                        <span
                          className="no-toggle"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleStatusDropdown(i);
                          }}
                        >
                          {check?.processingStatus?.status === 'COMPLETED' &&
                          !check?.prismDataUpdateStatus?.prismUpdated
                            ? 'Prism Not found'
                            : CheckStatusMap[check?.processingStatus?.status] ||
                              '-'}
                          {activeStatusDropdown === i && (
                            <div
                              ref={statusDropdownRef}
                              className="status-dropdown"
                            >
                              {Object.entries(CheckStatusMapOriginal).map(
                                ([statusKey, statusValue]) => (
                                  <div
                                    key={statusKey}
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleChangeStatus(check, statusKey);
                                    }}
                                  >
                                    {statusValue}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </span>
                      ) : null}
                      <span>{check?.remarks || '-'}</span>
                      <span className="no-toggle">
                        <Popconfirm
                          className="no-toggle"
                          title="Are you sure you want to delete this check?"
                          onConfirm={() => handleDeleteCheck(check?._id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Delete />
                        </Popconfirm>
                      </span>
                    </Row>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={rowHeaders.length}
                      style={{ textAlign: 'center', padding: '1rem' }}
                    >
                      <Empty description="No checks found" />
                    </td>
                  </tr>
                )}
              </Table>
            </div>

            {checks.length > 0 ? (
              <div className="pagination">
                <Paginator
                  offset={page}
                  totalElements={totalElements}
                  first={page === 1}
                  last={!hasMore}
                  pageNumber={page}
                  pageSize={10}
                  paginate={(newPage) => {
                    handlePagination(newPage);
                  }}
                  background="#f5f5f5"
                />
              </div>
            ) : null}
          </div>
        </div>
      </Box>
      <Modal
        title="Document Preview"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="80%"
      >
        {modalPdfUrl ? (
          <embed
            src={`${modalPdfUrl}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        ) : (
          <p>No document found</p>
        )}
      </Modal>
    </>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default ComplianceChecksPage;
