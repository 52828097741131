import apiEnvironment from './api';
import firebaseEnvironment from './firebase';
import pendoEnvironment from './pendo';

const environment = {
  api: apiEnvironment,
  firebase: firebaseEnvironment,
  pendo: pendoEnvironment,
};

export default environment;
