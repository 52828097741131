import { CheckStatusMap } from 'app/components/common/Checks/types';
import { documentNameMap } from 'app/configs/documentNameMap';

type Transaction = {
  type: string;
  readyToDeposit: boolean;
  remarks: string;
  processingStatus: {
    status: string;
  };
  prismDataUpdateStatus: { prismUpdated: boolean };
  receivingOffice: {
    name: string;
  };
  transactionOffice: {
    name: string;
  };
  transactionName: string;
  agentName: string;
};

const escapeCsvValue = (value: string): string => {
  if (value?.includes('"')) {
    value = value?.replace(/"/g, '""');
  }
  if (value?.includes(',') || value?.includes('\n') || value?.includes('"')) {
    return `"${value}"`;
  }
  return value;
};

export const DownloadCheckReport = (data: Transaction[]) => {
  const csvRows: string[] = [];

  // Define the headers
  const headers = [
    'Property Address',
    'Type',
    'Receiving Office',
    'Branch Office',
    'Agent Name',
    'Ready to Deposit',
    'Status',
    'Remarks',
  ];
  csvRows.push(headers.map(escapeCsvValue).join(','));

  // Extract and format the necessary fields
  data.forEach((item) => {
    const receivingOffice = item?.receivingOffice?.name;
    const transactionOffice = item?.transactionOffice?.name;
    const notes =
      item?.processingStatus?.status === 'COMPLETED' &&
      !item.prismDataUpdateStatus?.prismUpdated
        ? 'Prism not updated'
        : CheckStatusMap[item?.processingStatus?.status] ||
          item?.processingStatus?.status;

    const values = [
      item?.transactionName || '-',
      documentNameMap[item?.type] || '-',
      receivingOffice || '-',
      transactionOffice || '-',
      item?.agentName || '-',
      item?.readyToDeposit ? 'Yes' : 'No',
      notes || '-',
      item?.remarks || '-',
    ];

    csvRows.push(values.map(escapeCsvValue).join(','));
  });

  // Create a blob and trigger download
  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'Maxhome-report.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
