import { validateEnvVariable } from './helpers';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

const FIREBASE_API_KEY = validateEnvVariable(REACT_APP_FIREBASE_API_KEY);
const FIREBASE_AUTH_DOMAIN = validateEnvVariable(
  REACT_APP_FIREBASE_AUTH_DOMAIN
);
const FIREBASE_PROJECT_ID = validateEnvVariable(REACT_APP_FIREBASE_PROJECT_ID);
const FIREBASE_STORAGE_BUCKET = validateEnvVariable(
  REACT_APP_FIREBASE_STORAGE_BUCKET
);
const FIREBASE_MESSAGING_SENDER_ID = validateEnvVariable(
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID
);
const FIREBASE_APP_ID = validateEnvVariable(REACT_APP_FIREBASE_APP_ID);
const FIREBASE_MEASUREMENT_ID = validateEnvVariable(
  REACT_APP_FIREBASE_MEASUREMENT_ID
);

const firebaseEnvironment = {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
};

export default firebaseEnvironment;
