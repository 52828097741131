import { Route, Routes } from 'react-router-dom';
import OfficeAdminNavbar from './components/Navbar';
import './OfficeAdmin.scss';
import ComplianceChecksPage from 'app/components/common/Checks';

const OfficeAdminDashboard = () => {
  return (
    <>
      <div className="office-admin-dashboard">
        <div className="dashboard-container">
          <div className="navbar-container">
            <OfficeAdminNavbar />
          </div>
          <div className="content-container">
            <Routes>
              <Route
                path="checks"
                element={<ComplianceChecksPage isCompliance={false} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeAdminDashboard;
